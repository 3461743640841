// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { config } from './config'
import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import cloneDeep from 'lodash/cloneDeep'

Vue.use(Vuex)

axios.defaults.headers.common['Accept'] = 'application/json; version=2'
axios.defaults.headers.common['X-Pebblo-Platform'] = 'web'

const store = new Vuex.Store({
  state: {
    lang: 'fr',
    user: null,
    userInfo: null,
    languages: ['fr', 'en'],
    readonly: false,

    // for listing, not edition
    maps: null,
    layouts: null,
    pois: null,
    categories: null,

    // for edition
    app: {
      current_layout: {
        displayed_fields: {
          map_default_translated_fields: {}
        }
      }
    },
    currentLayout: null,
    currentMap: { name: '' },
    currentCategory: null,
    currentPoi: null,

    // for breadcrumbs
    lastUserName: null,
    lastSelectionName: null
  },

  mutations: {
    logout (state) {
      state.user = null
      state.app = {
        current_layout: {
          displayed_fields: {
            map_default_translated_fields: {}
          }
        }
      }
      state.maps = null
      state.layouts = null
      state.languages = ['fr', 'en']
      state.currentLayout = null
      state.currentMap = { name: '' }
      state.currentPoi = null
      state.userInfo = null
      state.currentCategory = null
      state.categories = null
      state.lastUserName = null
      state.lastSelectionName = null
      delete axios.defaults.headers.common['Authorization']
      document.title = 'Pebblo'
      router.push({path: `/auth`})
    },
    setUser (state, payload) {
      Vue.set(state, 'user', payload)
    },
    setApp (state, payload) {
      if (typeof payload.languages === 'undefined') {
        state.languages = ['fr', 'en']
      } else if (payload.languages.length === 0) {
        state.languages = ['fr', 'en']
      } else {
        let languages = []
        for (let lang of payload.languages) {
          languages.push(lang.code)
        }
        state.languages = languages
      }
      Vue.set(state, 'app', payload)
      document.title = `${payload.name} | Pebblo`
    },
    setMaps (state, payload) { Vue.set(state, 'maps', payload) },
    setLayouts (state, payload) { Vue.set(state, 'layouts', payload) },
    setPois (state, payload) {
      if (payload.merge) {
        Vue.set(state, 'pois', state.pois.concat(payload.pois))
      } else {
        Vue.set(state, 'pois', payload.pois)
      }
    },
    setCategories (state, payload) { Vue.set(state, 'categories', payload) },
    setLayout (state, payload) {
      for (let [index, entry] of Object.entries(state.layouts)) {
        if (entry.id === payload.id) {
          state.layouts[index] = payload
          Vue.set(state, 'layouts', state.layouts)
          break
        }
      }
    },
    setPoi (state, payload) {
      if (state.poi !== null && state.poi === payload.id) {
        Vue.set(state, 'poi', payload)
      }
      for (let poi of state.pois) {
        if (poi.id === payload.id) {
          Object.assign(poi, payload)
        }
      }
    },
    setUserInfo (state, payload) {
      if (state.user.user_id === payload.id) {
        Vue.set(state, 'userInfo', payload)
      }
    },
    addPoi (state, payload) {
      state.pois.push(payload)
    },
    deletePoi (state, id) {
      for (let [index, entry] of Object.entries(state.pois)) {
        if (entry.id === id) {
          state.pois.splice(index, 1)
          Vue.set(state, 'pois', state.pois)
        }
      }
    },
    setCurrentLayout (state, payload) { Vue.set(state, 'currentLayout', payload) },
    setCurrentMap (state, payload) { Vue.set(state, 'currentMap', payload) },
    setCurrentPoi (state, payload) { Vue.set(state, 'currentPoi', payload) },
    setCurrentCategory (state, payload) { Vue.set(state, 'currentCategory', payload) },
    setLastUserName (state, payload) { Vue.set(state, 'lastUserName', payload) },
    setLastSelectionName (state, payload) { Vue.set(state, 'lastSelectionName', payload) },
    updateCurrentPoi (state, payload) {
      Object.assign(state.currentPoi, payload)
    },
    updateCurrentMap (state, payload) {
      Object.assign(state.currentMap, payload)
    },
    addLayout (state, payload) {
      let layouts = state.layouts
      layouts.push(payload)
      Vue.set(state, 'layouts', layouts)
    }
  },

  getters: {
    getAppTitle: state => {
      try {
        return state.app.translated_fields.title.fr || state.app.name
      } catch (e) {
        return state.app.name
      }
    },
    getMapTitle: state => {
      try {
        return state.currentMap.translated_fields.title.fr || state.currentMap.name
      } catch (e) {
        return state.currentMap.name
      }
    },
    getPoiTitle: state => {
      try {
        return state.currentPoi.translated_fields.title.fr
      } catch (e) {
        return '...'
      }
    },
    getLayoutTitle: state => {
      try {
        return state.currentLayout.layout_fields.name
      } catch (e) {
        return '...'
      }
    },
    getUserName: state => {
      if (state.lastUserName !== null) return state.lastUserName
      return '...'
    },
    getFieldType: state => (category, name) => {
      for (let field of state.app.fields[category]) {
        if (field.field_name === name) return field.type
      }
    },
    getLastSelectionName: state => {
      if (state.lastSelectionName !== null) return state.lastSelectionName
      return '...'
    }
  },

  actions: {

    setAxiosAuthorizationFromUser ({ state }) {
      if (state.user !== null) {
        axios.defaults.headers.common['Authorization'] = (
          `Token ${state.user.token}`)
      }
    },

    preauth ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${config.baseUrl}/preauth/`, {
          email: payload.email
        }).then(function (response) {
          resolve(response)
        }).catch(function (data) {
          reject(new Error(data))
        })
      })
    },

    login ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${config.baseUrl}/auth-token/`, {
          mechanism: 'password',
          email: payload.email,
          password: payload.password,
          application_id: payload.application_id
        }).then(function (response) {
          commit('setUser', response.data)
          dispatch('setAxiosAuthorizationFromUser')
          resolve()
        }).catch(function (data) {
          console.log('error', data)
          reject(new Error(data))
        })
      })
    },

    async ensureUser ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        if (state.user === null) {
          router.push({path: `/auth`})
          reject(new Error('not authenticated'))
          return
        }
        resolve()
      })
    },

    async loadUser ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let url = `${config.baseUrl}/apps/${payload.appID}/users/${payload.userID}/`
        axios
          .get(url)
          .then(function (response) {
            commit('setLastUserName', response.data.name)
            resolve(response.data)
          })
      })
    },

    async updateUser ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.app.id
        let userID = payload.userID
        let url = `${config.baseUrl}/apps/${appID}/users/${userID}/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        delete payload.userID

        axios
          .patch(url, payload)
          .then(function (response) {
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    async deleteUser ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let url = `${config.baseUrl}/apps/${payload.appID}/users/${payload.userID}/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .delete(url)
          .then(function (response) {
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    createUser ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.app.id
        let url = `${config.baseUrl}/apps/${appID}/users/`
        if (state.readonly) return reject(new Error('readonly'))

        axios
          .post(url, payload)
          .then(function (response) {
            resolve(response.data)
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    async loadUsers ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let url = `${config.baseUrl}/apps/${payload.appID}/users/`
        axios
          .get(url, {
            params: {
              limit: payload.limit || 100,
              offset: payload.offset || 0
            }
          })
          .then(function (response) {
            resolve(response.data)
          })
      })
    },

    async exportUsers ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let url = `${config.baseUrl}/apps/${store.state.app.id}/users/export/`
        axios.get(url)
        .then((response) => {
          let fields = response.data.fields
          let items = response.data.data
          let fileTitle = 'users'
          let csv = JSON.stringify(fields).slice(1, -1) + '\r\n'
          for (let item of items) {
            for (let i = 0; i < item.length; i++) {
              if (item[i] === null) item[i] = ''
            }
            csv += JSON.stringify(item).slice(1, -1) + '\r\n'
          }

          // Convert Object to JSON
          var exportedFilename = fileTitle + '.csv' || 'export.csv'
          var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
          if (navigator.msSaveBlob) { // IE 10+
              navigator.msSaveBlob(blob, exportedFilename)
          } else {
              var link = document.createElement('a')
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob)
                  link.setAttribute('href', url)
                  link.setAttribute('download', exportedFilename)
                  link.style.visibility = 'hidden'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
              }
          }
        })
      })
    },

    async loadSelections ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let url = `${config.baseUrl}/apps/${state.app.id}/selections/`
        payload.expand_user = 1
        axios
          .get(url, {
            params: payload
          })
          .then(function (response) {
            resolve(response.data)
          })
      })
    },

    async loadSelection ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let url = `${config.baseUrl}/apps/${payload.appID}/selections/${payload.selectionID}/`
        delete payload.appID
        delete payload.selectionID
        axios
          .get(url, {
            params: payload
          })
          .then(function (response) {
            commit('setLastSelectionName', response.data.name)
            resolve(response.data)
          })
      })
    },

    async updateSelection ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.app.id
        let selectionID = payload.selectionID
        let url = `${config.baseUrl}/apps/${appID}/selections/${selectionID}/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        delete payload.selectionID

        axios
          .patch(url, payload, {
            headers: {
              'Accept': 'application/json; version=1'
            }
          })
          .then(function (response) {
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    async createSelection ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.app.id
        let url = `${config.baseUrl}/apps/${appID}/selections/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .post(url, payload)
          .then(function (response) {
            resolve(response.data)
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    async deleteSelection ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let url = `${config.baseUrl}/apps/${state.app.id}/selections/${payload.selectionID}/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .delete(url)
          .then(function (response) {
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    async ensureApp ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        if (state.app.id === payload.appID) {
          resolve()
        } else {
          dispatch('loadApp', payload).then(function () {
            resolve()
          }).catch(function () {
            reject(new Error('unable to ensure app'))
          })
        }
      })
    },

    async ensureMaps ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        if (state.maps !== null) {
          resolve()
        } else {
            dispatch('loadMaps', payload).then(function () {
            resolve()
          }).catch(function () {
            reject(new Error('unable to ensure maps'))
          })
        }
      })
    },

    async ensureMap ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        try {
          if (state.map.id === payload.mapID && state.map.application === payload.appID) {
            resolve()
            return
          }
        } catch (e) {}
        dispatch('loadMap', payload).then(function () {
          resolve()
        }).catch(function () {
          reject(new Error('unable to ensure map'))
        })
      })
    },

    async loadApp ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/apps/${payload.appID}/`)
          .then(function (response) {
            commit('setApp', response.data)
            resolve()
          }).catch(function (data) {
            console.log('error', data)
            commit('logout')
          })
      })
    },

    async ensureLayouts ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        if (state.layouts !== null) {
          resolve()
        } else {
            dispatch('loadLayouts', payload).then(function () {
            resolve()
          }).catch(function () {
            reject(new Error('unable to ensure layouts'))
          })
        }
      })
    },

    async loadLayouts ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let url = `${config.baseUrl}/apps/${state.app.id}/layouts/`
        axios
          .get(url)
          .then(function (response) {
            commit('setLayouts', response.data)
            resolve()
          })
      })
    },

    async loadMaps ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let url = `${config.baseUrl}/apps/${payload.appID}/maps/`
        axios
          .get(url)
          .then(function (response) {
            commit('setMaps', response.data)
            resolve()
          })
      })
    },

    async loadPois ({ commit, state, dispatch }, payload) {
      const limit = payload.limit || 1000
      const offset = payload.offset || 0
      return new Promise((resolve, reject) => {
        let url = `${config.baseUrl}/apps/${payload.appID}/maps/${payload.mapID}/poi/`
        axios
          .get(url, {params: {limit, offset}})
          .then(function (response) {
            commit('setPois', {
              pois: response.data.results,
              merge: (offset > 0)
            })
            resolve(response)
          })
      })
    },

    async loadCategories ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let url = `${config.baseUrl}/apps/${payload.appID}/categories/`
        axios
          .get(url)
          .then(function (response) {
            commit('setCategories', response.data)
            resolve()
          })
      })
    },

    async ensureCategories ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        if (state.categories !== null) {
          resolve()
        } else {
            dispatch('loadCategories', payload).then(function () {
            resolve()
          }).catch(function () {
            reject(new Error('unable to ensure categories'))
          })
        }
      })
    },

    async createCategory ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.app.id
        let categoryID = payload.parentId
        let url
        if (categoryID !== -1) {
          url = `${config.baseUrl}/apps/${appID}/categories/${categoryID}/add/`
        } else {
          url = `${config.baseUrl}/apps/${appID}/categories/`
        }
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }
        delete payload.parentId

        axios
          .post(url, payload)
          .then(function (response) {
            commit('setCurrentCategory', response.data)
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    async updateCategory ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.app.id
        let categoryID = payload.id
        let url = `${config.baseUrl}/apps/${appID}/categories/${categoryID}/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }
        delete payload.id

        axios
          .patch(url, payload)
          .then(function (response) {
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    async deleteCategory ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.app.id
        let categoryID = payload.id
        let url = `${config.baseUrl}/apps/${appID}/categories/${categoryID}/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .delete(url, payload)
          .then(function (response) {
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    async updateCurrentLayout ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.currentLayout.application
        let layoutID = state.currentLayout.id
        let url = `${config.baseUrl}/apps/${appID}/layouts/${layoutID}/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .patch(url, payload)
          .then(function (response) {
            // patch response.data with payload, as the result
            // never contain the change on our api (django-rest ?)
            Object.assign(response.data, payload)
            commit('setCurrentLayout', response.data)
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    async updateCurrentMap ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.currentMap.application
        let mapID = state.currentMap.id
        let url = `${config.baseUrl}/apps/${appID}/maps/${mapID}/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .patch(url, payload)
          .then(function (response) {
            // patch response.data with payload, as the result
            // never contain the change on our api (django-rest ?)
            Object.assign(response.data, payload)
            commit('setCurrentMap', response.data)
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    async updateCurrentApp ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.app.id
        let url = `${config.baseUrl}/apps/${appID}/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .patch(url, payload)
          .then(function (response) {
            // patch response.data with payload, as the result
            // never contain the change on our api (django-rest ?)
            Object.assign(response.data, payload)
            commit('setApp', response.data)
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    async updatePoi ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = payload.appID
        let mapID = payload.mapID
        let poiID = payload.poiID
        let url = `${config.baseUrl}/apps/${appID}/maps/${mapID}/poi/${poiID}/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .patch(url, payload)
          .then(function (response) {
            // patch response.data with payload, as the result
            // never contain the change on our api (django-rest ?)
            Object.assign(response.data, payload)
            // FIX: also sometimes map is a string?
            try {
              response.data.map = parseInt(response.data.map)
            } catch (e) {}
            commit('setPoi', response.data)
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    async updateCurrentPoiMap ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.currentPoi.application
        let mapID = state.currentPoi.map
        let poiID = state.currentPoi.id
        let url = `${config.baseUrl}/apps/${appID}/maps/${mapID}/poi/${poiID}/move_to_map/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .post(url, payload)
          .then(function (response) {
            // trick to force mutation happen (otherwise it's not dispatched correctly)
            let poi = cloneDeep(state.currentPoi)
            poi.map = payload.map_id
            Vue.set(state, 'currentPoi', poi)
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    async updateCurrentPoi ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.currentPoi.application
        let mapID = state.currentPoi.map
        let poiID = state.currentPoi.id
        let url = `${config.baseUrl}/apps/${appID}/maps/${mapID}/poi/${poiID}/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .patch(url, payload)
          .then(function (response) {
            // patch response.data with payload, as the result
            // never contain the change on our api (django-rest ?)
            Object.assign(response.data, payload)
            // FIX: also sometimes map is a string?
            try {
              response.data.map = parseInt(response.data.map)
            } catch (e) {}
            commit('setCurrentPoi', response.data)
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    loadMap ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/apps/${payload.appID}/maps/${payload.mapID}/`)
          .then(function (response) {
            commit('setCurrentMap', response.data)
            resolve()
          })
      })
    },

    loadUserInfo ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        if (state.user === null) {
          router.push({path: `/auth`})
          reject(new Error('not authenticated'))
          return
        }
        axios
          .get(`${config.baseUrl}/apps/${payload.appID}/users/${payload.userID}/`)
          .then(function (response) {
            commit('setUserInfo', response.data)
            resolve()
          })
      })
    },

    searchPoi ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.app.id
        let url = `${config.baseUrl}/apps/${appID}/poi/`
        if (state.readonly) return reject(new Error('readonly'))

        axios
          .get(url, {params: payload})
          .then(function (response) {
            resolve(response.data)
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    createPoi ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = payload.appID
        let mapID = payload.mapID
        let poiData = payload.payload
        let url = `${config.baseUrl}/apps/${appID}/maps/${mapID}/poi/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .post(url, poiData)
          .then(function (response) {
            commit('addPoi', response.data)
            resolve(response.data)
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    deletePoi ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = payload.appID
        let mapID = payload.mapID
        let poiID = payload.poiID
        let url = `${config.baseUrl}/apps/${appID}/maps/${mapID}/poi/${poiID}`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .delete(url)
          .then(function (response) {
            commit('deletePoi', poiID)
            resolve(response.data)
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    createMap ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = payload.appID
        let mapData = payload.payload
        let url = `${config.baseUrl}/apps/${appID}/maps/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .post(url, mapData)
          .then(function (response) {
            resolve(response.data)
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    createLayout ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.app.id
        let url = `${config.baseUrl}/apps/${appID}/layouts/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .post(url, payload)
          .then(function (response) {
            // patch response.data with payload, as the result
            // never contain the change on our api (django-rest ?)
            Object.assign(response.data, payload)
            commit('addLayout', response.data)
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    },

    loadPoi ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/apps/${payload.appID}/maps/${payload.mapID}/poi/${payload.poiID}/`)
          .then(function (response) {
            commit('setCurrentPoi', response.data)
            resolve()
          })
      })
    },

    loadAppPoi ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/apps/${state.app.id}/poi/${payload.poiID}/`)
          .then(function (response) {
            resolve(response.data)
          })
      })
    },

    loadLayout ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.baseUrl}/apps/${payload.appID}/layouts/${payload.layoutID}/`)
          .then(function (response) {
            commit('setCurrentLayout', response.data)
            resolve()
          })
      })
    },

    async updateLayout ({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let appID = state.app.id
        let layoutID = payload.layoutID
        let url = `${config.baseUrl}/apps/${appID}/layouts/${layoutID}/`
        if (state.readonly) return reject(new Error('readonly'))
        if (state.user === null) {
          reject(new Error('not authenticated'))
          return
        }

        axios
          .patch(url, payload.payload)
          .then(async function (response) {
            // patch response.data with payload, as the result
            // never contain the change on our api (django-rest ?)
            Object.assign(response.data, payload)
            commit('setLayout', response.data)
            await dispatch('loadApp', { appID })
            await dispatch('loadLayouts')
            resolve()
          }).catch(function (error) {
            reject(error)
          })
      })
    }
  },

  plugins: [createPersistedState({
    paths: [
      'user',
      'userInfo',
      'readonly'
    ]
  })]
})

export default store
