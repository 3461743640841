<template>
  <el-container id="app" v-bind:class="classObject">
    <el-header height="60">
      <div id="navbar">
        <h1><img src="./assets/logo.png"/><router-link :to="{path: '/'}">Pebblo</router-link></h1>
        <div id="userbar" v-if="$store.state.user">

          <el-tag type="danger" size="mini" class="readonlytag" v-if="$store.state.readonly">Lecture seule</el-tag>
          <el-dropdown @command="gotoSubMenu($event)">
            <span class="el-dropdown-link">
              <img src="./assets/default-user.png" class="user-icon"/>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item command="myaccount">{{ $t('g.myaccount') }}</el-dropdown-item> -->
              <el-dropdown-item command="logout" divided>{{ $t('g.logout') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div id="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">{{ $t('nav.home') }}</el-breadcrumb-item>
          <breadcrumb :route="route" v-for="route in routeMatched" v-bind:key='route.path'></breadcrumb>
        </el-breadcrumb>
      </div>
    </el-header>
    <el-main class="fullheight" :style="{'background-color': backgroundColor}">
      <router-view/>
    </el-main>
  </el-container>
</template>

<script>
import Breadcrumb from './components/Breadcrumb'
export default {
  name: 'App',
  components: { Breadcrumb },
  data () {
    return {}
  },
  methods: {
    gotoSubMenu (ev) {
      if (ev === 'myaccount') {
        this.$router.push({name: 'myaccount'})
      } else if (ev === 'logout') {
        this.$store.commit('logout')
        this.$router.push({name: 'login'})
      }
    }
  },
  computed: {
    routeMatched () {
      return this.$route.matched.filter(function (entry) {
        if (typeof entry.meta === 'undefined') return
        if (entry.meta.bcDynamic === true || entry.meta.bcLinkText) return entry
      })
    },

    classObject () {
      let obj = {
        'embed': this.isEmbed
      }
      if (this.$store.state.app !== undefined && this.$store.state.app.id !== undefined) {
        obj['app' + this.$store.state.app.id] = true
      }
      if (this.$store.state.currentMap.id !== undefined) {
        obj['map' + this.$store.state.currentMap.id] = true
      }
      if (this.haveCustomBackgroundColor) {
        obj['background-via-params'] = true
      } else {
        obj['background-via-css'] = true
      }
      return obj
    },

    isEmbed () {
      return this.$route.query.mode === 'embed'
    },

    backgroundColor () {
      let backgroundColor = 'transparent'
      if (this.isEmbed && this.$route.query.backgroundColor) {
        backgroundColor = '#' + this.$route.query.backgroundColor
      }
      return backgroundColor
    },

    haveCustomBackgroundColor () {
      return this.backgroundColor !== 'transparent'
    }
  }
}
</script>

<style scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666666;
  min-height: 100%;
}

#breadcrumb {
  background-color: #f5f7fa;
  margin: 0 -20px;
  padding: 10px 20px;
  border-bottom: 1px solid #e4e7ed;
  border-top: 1px solid #e4e7ed;
}

#navbar {
  height: 60px;
  display: flex;
}

#navbar h1 {
  line-height: 60px;
  flex-grow: 1;
}

#navbar h1 img {
  max-height: 36px;
  max-width: 36px;
  vertical-align: middle;
  margin-right: 10px;
}

#userbar {
  height: 40px;
  align-self: center;
  display: flex;
  align-items: center;
}
.user-icon {
  height: 40px;
  width: 40px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  display: flex;
  align-items: center;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.readonlytag {
  margin-right: 10px;
}

#app.embed .el-header {
  display: none;
}

</style>
