
export const config = {
  baseUrl: 'https://pebblo.herokuapp.com',
  // baseUrl: 'http://127.0.0.1:8000',
  proxyUrl: 'https://media.pebblo.io/unsafe',
  bucketUrl: 'https://s3-us-west-2.amazonaws.com/pebblo2',
  // mapProxyUrl: 'http://map.pebblo.localhost:5000/',
  mapProxyUrl: 'https://map.pebblo.io/',
  languages: {
    fr: 'Français',
    en: 'Anglais',
    de: 'Allemand',
    es: 'Espagnol',
    it: 'Italien',
    es_CT: 'Catalan',
    nl: 'Néerlandais',
  },
  fieldTranslatedTypes: ['string', 'longstring'],
  fieldTypes: ['string', 'longstring', 'integer', 'float', 'boolean', 'poi_id', 'map_id'],
  userFieldTypes: ['string', 'longstring', 'integer', 'boolean'],
  protectedFields: [
    'poi_default_translated_fields.title',
    'map_default_translated_fields.title',
    'category_default_translated_fields.name'
  ],
  fieldStyles: [
    {
      label: 'Titre',
      value: 'title'
    },
    {
      label: 'Sous-titre',
      value: 'subtitle'
    },
    {
      label: 'Texte',
      value: 'text'
    },
    {
      label: 'Element de liste',
      value: 'list-item'
    }
  ],
  defaultFields: {
    poi_default_content_fields: [],
    poi_default_translated_fields: [
      {
        'type': 'string',
        'displayed_name': {
          'fr': 'Titre',
          'en': 'Title'
        },
        'field_name': 'title'
      },
      {
        'type': 'string',
        'displayed_name': {
          'fr': 'Description',
          'en': 'Description'
        },
        'field_name': 'description'
      }
    ],
    selection_default_content_fields: [],
    selection_default_translated_fields: [],
    category_default_content_fields: [],
    category_default_translated_fields: [
      {
        'type': 'string',
        'displayed_name': {
          'fr': 'Nom',
          'en': 'Name'
        },
        'field_name': 'name'
      }
    ],
    map_default_content_fields: [],
    map_default_translated_fields: [
      {
        'type': 'string',
        'displayed_name': {
          'fr': 'Titre',
          'en': 'Title'
        },
        'field_name': 'title'
      },
      {
        'type': 'string',
        'displayed_name': {
          'fr': 'Description',
          'en': 'Description'
        },
        'field_name': 'description'
      }
    ],
    user_default_profile_fields: [
      {
        'type': 'string',
        'displayed_name': {
          'fr': 'Nom',
          'en': 'First name'
        },
        'field_name': 'first_name'
      },
      {
        'type': 'string',
        'displayed_name': {
          'fr': 'Prénom',
          'en': 'Last name'
        },
        'field_name': 'last_name'
      }
    ]
  }
}
