<template>
    <el-breadcrumb-item v-if="!route.meta.bcDynamic" :to="{name: route.name||route.meta.bcRootName, params: $route.params}">{{ route.meta.bcLinkText }}</el-breadcrumb-item>

    <el-breadcrumb-item v-else :to= "{name: route.name||route.meta.bcRootName, params: $route.params}">
      <template v-if="value">
        {{formattedValue}}
      </template>
      <template v-if="!value">
        {{loadingText}}
      </template>
    </el-breadcrumb-item>
</template>

<script>
export default {
  name: 'breadcrumb',
  props: ['route'],
  template: '#breadcrumb',
  beforeCreate () {
    this.$options.computed.value = function () {
      if (this.route.meta.bcGetter) {
        return this.$store.getters[this.route.meta.bcGetter]
      } else {
        return null
      }
    }
  },
  computed: {
    formattedValue () { return this.route.meta.bcLinkText(this.value) },
    loadingText () {
      const loadingText = this.route.meta.bcLoadingText
      if (loadingText) {
        return loadingText
      }
      return 'Loading ...'
    }
  }}
</script>

<style>
</style>
