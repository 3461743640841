import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'

Vue.use(VueI18n)

const messages = {
  fr: {
    login: {
      title: 'Bienvenue',
      information: 'Avec Pebblo, créez simplement votre carte interactive personnalisée',
      email: 'Email',
      password: 'Mot de passe',
      login: 'Se connecter',
      passwordLost: 'J\'ai perdu mon mot de passe',
      error: 'Login ou mot de passe invalide',
      application: 'Application',
      reset: 'Changer de compte utilisateur'
    },
    title: {
      appindex: 'Mon application',
      appmaps: 'Mes cartes',
      applayouts: 'Mes layouts',
      appmap: 'Ma carte',
      appsettings: 'Paramètres',
      applayout: 'Mon layout',
      apppois: 'Mes POIs',
      appusers: 'Utilisateurs',
      appuser: 'Utilisateur',
      apppoi: 'POI',
      appselections: 'Sélections',
      appselection: 'Sélection'
    },
    lang: {
      fr: 'Français',
      en: 'Anglais',
      de: 'Allemand',
      es: 'Espagnol',
      es_CT: 'Catalan'
    },
    nav: {
      home: 'Accueil'
    },
    fieldTypes: {
      string: 'Texte',
      longstring: 'Texte multiligne',
      integer: 'Nombre entier',
      float: 'Nombre à virgule',
      boolean: 'Boolean',
      poi_id: 'POI',
      map_id: 'Carte'
    },
    user: {
      is_staff: 'Staff',
      is_contributor: 'Contributor',
      permissions: 'Permissions',
      staff: 'Staff',
      moderator: 'Modérateur',
      contributor: 'Contributeur',
      allow_display_current_position: 'Autoriser l\'affichage de la position courante'
    },
    selection: {
      public: 'Accessible au public',
      typology: 'Typologie'
    },
    g: {
      auto: 'Auto',
      title: 'Titre',
      save: 'Sauvegarder',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      delete: 'Supprimer',
      ok: 'OK',
      user: 'Utilisateur',
      created_by: 'Créé par',
      created_at: 'Date de création',
      status: 'Status',
      myaccount: 'Mon compte',
      logout: 'Se déconnecter',
      name: 'Nom',
      email: 'Email',
      section_general: 'Général',
      section_fields: 'Champs',
      section_debug: '@',
      section_admin: 'Admin',
      section_map: 'Carte',
      section_permissions: 'Permissions',
      section_pois: 'POIs',
      section_geojson_style: 'Polygon',
      lat: 'Latitude',
      lon: 'Lontigude',
      bounds: 'Limites',
      zoom: 'Zoom',
      zoom_default: 'Zoom initial',
      order: 'Ordre',
      center: 'Centre',
      typology: 'Typology',
      categories: 'Catégories',
      min_zoom: 'Zoom minimum',
      max_zoom: 'Zoom maximum',
      icon_url: 'Icône URL',
      icon_selected_url: 'Icône URL en sélection',
      icon_anchor: 'Ancre de l\'icône',
      poi_state: 'Publication',
      poi_map_linked: 'Carte liée',
      icon_dimensions: 'Dimensions de l\'icône',
      icon_dimensions_custom: 'Taille personnalisée',
      icon_custom_size_width: 'Largeur',
      icon_custom_size_height: 'Height',
      icon_custom_box_size: 'Boite (px)',
      source_url: 'Carte URL',
      minimum: 'Minimum',
      maximum: 'Maximum',
      by_default: 'Par défaut',
      nobounds: 'Pas de limites',
      languages: 'Langues',
      fieldtype: 'Type du champ',
      fieldname: 'ID du champ',
      fielddisplayedname: 'Titre du champ',
      fieldstyle: 'Style du champ',
      poi_default_content_fields: 'POI: Champs sans traduction',
      poi_default_translated_fields: 'POI: Champs avec traduction',
      selection_default_content_fields: 'Sélection: Champs sans traduction',
      selection_default_translated_fields: 'Sélection: Champs avec traduction',
      category_default_content_fields: 'Catégorie: Champs sans traduction',
      category_default_translated_fields: 'Catégorie: Champs avec traduction',
      map_default_content_fields: 'Carte: Champs sans traduction',
      map_default_translated_fields: 'Carte: Champs avec traduction',
      user_default_profile_fields: 'Utilisateur: Champs sans traduction',
      uploadicon: 'Uploader une icône',
      uploadmap: 'Uploader une carte',
      indoor: {
        false: {
          max_lon: 'Longitude maximum',
          min_lon: 'Longitude minimum',
          min_lat: 'Latitude minimum',
          max_lat: 'Latitude maximum',
          lat: 'Latitude',
          lon: 'Lontigude'
        },
        true: {
          max_lon: 'X max',
          min_lon: 'X min',
          min_lat: 'Y min',
          max_lat: 'Y max',
          lat: 'Y',
          lon: 'X'
        }
      }
    }
  }
}

const i18n = new VueI18n({
  locale: 'fr',
  messages: messages
})
i18n.fallbackLocale = 'fr'
locale.use('fr')

export default i18n
