import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import store from '@/store.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import(/* webpackChunkName: 'login' */ '@/components/Index.vue'),
      meta: {
        isPublic: true
      }
    },
    {
      path: '/public/apps/:appID/maps/:mapID',
      component: () => import(/* webpackChunkName: 'PublicMap' */ '@/components/PublicMap.vue'),
      beforeEnter: (to, from, next) => {
        if (to.query.lang !== undefined) {
          i18n.locale = to.query.lang
        }
        if (to.query.fallbackLang !== undefined) {
          i18n.fallbackLocale = to.query.fallbackLang
        }
        next()
      },
      props: route => ({
        appID: Number(route.params.appID),
        mapID: Number(route.params.mapID)
      }),
      meta: {
        isPublic: true
      }
    },
    {
      path: '/public/apps/:appID/maps/:mapID/360',
      name: 'public-map-360',
      component: () => import(/* webpackChunkName: 'PublicMap360' */ '@/components/PublicMap360.vue'),
      beforeEnter: (to, from, next) => {
        if (to.query.lang !== undefined) {
          i18n.locale = to.query.lang
        }
        if (to.query.fallbackLang !== undefined) {
          i18n.fallbackLocale = to.query.fallbackLang
        }
        next()
      },
      props: route => ({
        appID: Number(route.params.appID),
        mapID: Number(route.params.mapID)
      }),
      meta: {
        isPublic: true
      }
    },
    {
      path: '/admin/apps',
      name: 'admin-apps',
      component: () => import(/* webpackChunkName: 'admin' */ '@/components/admin/AdminApps.vue'),
    },
    {
      path: '/admin/app',
      component: { template: '<router-view/>' },
      children: [
        {
          path: ':appID',
          name: 'admin-app',
          component: () => import(/* webpackChunkName: 'admin' */ '@/components/admin/AdminApp.vue'),
          props: route => ({
            appID: Number(route.params.appID)
          }),
        },
      ],
    },
    {
      path: '/apps',
      component: { template: '<router-view/>' },
      children: [
        {
          path: ':appID',
          component: { template: '<router-view/>' },
          meta: {
            bcDynamic: true,
            bcGetter: 'getAppTitle',
            bcLinkText: entry => entry,
            bcLoadingText: '...',
            bcRootName: 'appindex'
          },
          children: [
            {
              path: '',
              name: 'appindex',
              component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppIndex.vue'),
              props: route => ({
                appID: Number(route.params.appID)
              })
            },
            {
              path: 'settings',
              name: 'appsettings',
              component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppSettings.vue'),
              props: route => ({
                appID: Number(route.params.appID)
              }),
              meta: {
                bcLinkText: 'Paramètres'
              }
            },
            {
              path: 'selections',
              component: { template: '<router-view/>' },
              meta: {
                bcLinkText: 'Sélections',
                bcRootName: 'appselections'
              },
              children: [
                {
                  path: '',
                  name: 'appselections',
                  component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppSelections.vue'),
                  props: route => ({
                    appID: Number(route.params.appID)
                  })
                },
                {
                  path: ':selectionID',
                  name: 'appselection',
                  component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppSelection.vue'),
                  props: route => ({
                    appID: Number(route.params.appID),
                    selectionID: Number(route.params.selectionID)
                  }),
                  meta: {
                    bcDynamic: true,
                    bcGetter: 'getLastSelectionName',
                    bcLinkText: entry => entry,
                    bcLoadingText: '...'
                  }
                }
              ]
            },
            {
              path: 'users',
              component: { template: '<router-view/>' },
              meta: {
                bcLinkText: 'Users',
                bcRootName: 'appusers'
              },
              children: [
                {
                  path: '',
                  name: 'appusers',
                  component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppUsers.vue'),
                  props: route => ({
                    appID: Number(route.params.appID)
                  })
                },
                {
                  path: ':userID',
                  name: 'appuser',
                  component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppUser.vue'),
                  props: route => ({
                    appID: Number(route.params.appID),
                    userID: Number(route.params.userID)
                  }),
                  meta: {
                    bcDynamic: true,
                    bcGetter: 'getUserName',
                    bcLinkText: entry => entry,
                    bcLoadingText: '...'
                  }
                }
              ]
            },
            {
              path: 'layouts',
              component: { template: '<router-view/>' },
              meta: {
                bcLinkText: 'Layouts',
                bcRootName: 'applayouts'
              },
              children: [
                {
                  path: '',
                  name: 'applayouts',
                  component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppLayouts.vue'),
                  props: route => ({
                    appID: Number(route.params.appID)
                  })
                },
                {
                  path: ':layoutID',
                  name: 'applayout',
                  component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppLayout.vue'),
                  props: route => ({
                    appID: Number(route.params.appID),
                    layoutID: Number(route.params.layoutID)
                  }),
                  meta: {
                    bcDynamic: true,
                    bcGetter: 'getLayoutTitle',
                    bcLinkText: entry => entry,
                    bcLoadingText: '...'
                  }
                }
              ]
            },
            {
              path: 'categories',
              name: 'appcategories',
              component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppCategories.vue'),
              props: route => ({
                appID: Number(route.params.appID)
              }),
              meta: {
                bcLinkText: 'Catégories'
              }
            },
            {
              path: 'maps',
              component: { template: '<router-view/>' },
              meta: {
                bcLinkText: 'Cartes',
                bcRootName: 'appmaps'
              },
              children: [
                {
                  path: '',
                  name: 'appmaps',
                  component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppMaps.vue'),
                  props: route => ({
                    appID: Number(route.params.appID)
                  })
                },
                {
                  path: 'live/:mapID',
                  component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppMapFull.vue'),
                  props: route => ({
                    appID: Number(route.params.appID),
                    mapID: Number(route.params.mapID)
                  }),
                  meta: {
                    bcRootName: 'applivemap',
                    bcDynamic: true,
                    bcGetter: 'getMapTitle',
                    bcLinkText: entry => entry,
                    bcLoadingText: '...'
                  },
                  children: [
                    {
                      path: '',
                      name: 'applivemap',
                      props: route => ({
                        appID: Number(route.params.appID),
                        mapID: Number(route.params.mapID),
                        mapedit: {embed: true}
                      }),
                      components: {
                        mapedit: () => import(/* webpackChunkName: 'editor' */ '@/components/AppMap.vue'),
                      }
                    },
                    {
                      path: 'pois/:poiID',
                      props: route => ({
                        appID: Number(route.params.appID),
                        mapID: Number(route.params.mapID),
                        poiID: Number(route.params.poiID),
                        poiedit: {embed: true},
                        mapedit: {embed: true}
                      }),
                      components: {
                        poiedit: () => import(/* webpackChunkName: 'editor' */ '@/components/AppPoi.vue'),
                        mapedit: () => import(/* webpackChunkName: 'editor' */ '@/components/AppMap.vue'),
                      },
                      name: 'applivepoi',
                      meta: {
                        bcDynamic: true,
                        bcGetter: 'getPoiTitle',
                        bcLinkText: entry => entry,
                        bcLoadingText: '...'
                      }
                    }
                  ]
                },
                {
                  path: ':mapID',
                  component: { template: '<router-view/>' },
                  meta: {
                    bcRootName: 'appmap',
                    bcDynamic: true,
                    bcGetter: 'getMapTitle',
                    bcLinkText: entry => entry,
                    bcLoadingText: '...'
                  },
                  children: [
                    {
                      path: '',
                      name: 'appmap',
                      component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppMap.vue'),
                      props: route => ({
                        appID: Number(route.params.appID),
                        mapID: Number(route.params.mapID)
                      })
                    },
                    {
                      path: 'pois',
                      component: { template: '<router-view/>' },
                      meta: {
                        bcLinkText: 'POIs',
                        bcRootName: 'apppois'
                      },
                      children: [
                        {
                          path: '',
                          name: 'apppois',
                          component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppPois.vue'),
                          props: route => ({
                            appID: Number(route.params.appID),
                            mapID: Number(route.params.mapID)
                          })
                        },
                        {
                          path: ':poiID',
                          component: () => import(/* webpackChunkName: 'editor' */ '@/components/AppPoi.vue'),
                          name: 'apppoi',
                          props: route => ({
                            appID: Number(route.params.appID),
                            mapID: Number(route.params.mapID),
                            poiID: Number(route.params.poiID)
                          }),
                          meta: {
                            bcDynamic: true,
                            bcGetter: 'getPoiTitle',
                            bcLinkText: entry => entry,
                            bcLoadingText: '...'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/auth',
      name: 'login',
      component: () => import(/* webpackChunkName: 'login' */ '@/components/Login.vue'),
      meta: {
        isPublic: true
      }
    },

    // redirection for PBA
    {
      path: '/app/:appID/map/:mapID',
      // redirect: '/public/apps/:appID/maps/:mapID'
      redirect: to => {
        const { query } = to
        let newQuery = {
          'backgroundColor': query.backgroundColor || '030303',
          'mode': query.mode || 'embed'
        }
        if (query.poi_list !== undefined) {
          newQuery['poiList'] = query.poi_list
          newQuery['hideCategories'] = 1
        }
        return {
          path: '/public/apps/:appID/maps/:mapID',
          query: newQuery
        }
      },
      meta: {
        isPublic: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (store.state.user !== null) {
    next()
    return
  }
  if (to.meta.isPublic === true) {
    next()
    return
  }
  next('/')
})

export default router
