// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { config } from './config'
import Vue from 'vue'
import App from './App'
import {
  Button,
  Select,
  Breadcrumb,
  BreadcrumbItem,
  Main,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Header,
  Container,
  Card,
  Input,
  Row,
  Col,
  ButtonGroup,
  TabPane,
  Checkbox,
  InputNumber,
  Tabs,
  Option,
  CheckboxButton,
  CheckboxGroup,
  Tree,
  Loading,
  Table,
  TableColumn,
  Pagination,
  Dialog,
  Switch,
  Tag,
  Popover,
  Alert,
  Transfer,
  Collapse,
  CollapseItem,
  Form,
  Message,
  Notification,
  MessageBox,
  Slider,
  ColorPicker
 } from 'element-ui'
import router from './router'
import store from './store'
import i18n from './i18n'
import { asset, hashCode } from './utils'
import 'element-ui/lib/theme-chalk/index.css'
import '../static/main.css'
import '../static/app4.scss'
import '../static/app10.scss'
import 'leaflet/dist/leaflet.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons/faCircle'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ElTableColumnPro from '@/components/ElTableColumnPro/index'

// fixes for leaflet, found in their examples
import L from 'leaflet'
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.config.productionTip = false

// Manually import Vue components
Vue.use(Button)
Vue.use(Select)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Main)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Header)
Vue.use(Container)
Vue.use(Card)
Vue.use(Input)
Vue.use(Loading.directive)
Vue.use(ButtonGroup)
Vue.use(TabPane)
Vue.use(Checkbox)
Vue.use(InputNumber)
Vue.use(Tabs)
Vue.use(Row)
Vue.use(Col)
Vue.use(Option)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Tree)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Switch)
Vue.use(Tag)
Vue.use(Popover)
Vue.use(Alert)
Vue.use(Transfer)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Form)
Vue.use(Slider)
Vue.use(ColorPicker)
Vue.prototype.$loading = Loading.service
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

Vue.use(ElTableColumnPro)

// font awesome
library.add(farCircle)
library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.filter('asset', asset)

Vue.filter('tr', function (value) {
  return `${value[this.$i18n.locale]}`
})

// add tr() filter that can be used to extract fr/en/de... from a
// translated_fields or content_fields etc.
Vue.mixin({
  methods: {
    trf (row, column, value) {
      try {
        return `${value[this.$i18n.locale]}`
      } catch (e) {
        return `(missing translated_field ${this.$i18n.locale})`
      }
    },
    tr (value, rowProperties) {
      try {
        return `${value[this.$i18n.locale]}`
      } catch (e) {
        return ''
      }
    },
    tf (data, key, lang, defaultValue) {
      if (typeof lang === 'undefined') {
        lang = this.$i18n.locale
      }
      try {
        return data.translated_fields[key][lang]
      } catch (e) {
        return defaultValue || ''
      }
    },
    cf (data, key) {
      try {
        return `${data.content_fields[key][this.$i18n.locale]}`
      } catch (TypeError) {
        return ''
      }
    },
    getCurrentLayoutFieldDefinition (section, key) {
      let fields = this.$store.state.app.current_layout.displayed_fields[section]
      for (let [key, field] of Object.entries(fields)) {
        if (field.field_name === key) {
          return field
        }
      }
    }
  }
})

// support for tile source url proxy
Vue.mixin({
  methods: {
    getSourceUrl (sourceUrl) {
      if (sourceUrl.length !== 0 && sourceUrl.indexOf('http') !== 0) {
        if (sourceUrl.indexOf('/') === 0) {
          sourceUrl = this.$config.bucketUrl + sourceUrl
        } else {
          sourceUrl = this.$config.bucketUrl + '/' + sourceUrl
        }
      }

      // append proxy to source url
      // XXX test with an image
      if (this.$config.mapProxyUrl === undefined || sourceUrl.indexOf('{z}') === -1) {
        return sourceUrl
      }

      let cacheKey = hashCode(sourceUrl)
      let proxySourceUrl = (
        this.$config.mapProxyUrl +
        cacheKey +
        '/apps/' +
        this.$store.state.app.id +
        '/maps/' +
        this.$store.state.currentMap.id +
        '/tile/{z}/{x}/{y}')

      return proxySourceUrl
    }
  }
})

/* eslint-disable no-new */
Vue.prototype.$config = config
new Vue({
  el: '#app',
  router,
  i18n,
  components: { App },
  template: '<App/>',
  store,
  created () {
    this.$store.dispatch('setAxiosAuthorizationFromUser')
  }
})
