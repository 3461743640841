import { config } from './config'

function hashCode (s) {
  var hash = 0
  let i, chr
  if (s.length === 0) return hash
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i)
    hash = ((hash << 5) - hash) + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

function asset (value, width, height, cover) {
  if (!value) return ''
  let idx = value.indexOf(config.bucketUrl)
  if (idx === -1) {
    idx = value.indexOf(config.bucketUrl.replace('https://', 'http://'))
  }
  let idxHttp = value.indexOf('http://') !== -1 || value.indexOf('https://') !== -1
  let transform = (cover === true) ? '' : '/fit-in'
  if (idx === 0 || (idx === -1 && !idxHttp)) {
    let filename = value
    filename = filename.replace('http://', 'https://')
    filename = filename.replace(config.bucketUrl, '')

    if (filename.indexOf('/') !== 0) {
      filename = '/' + filename
    }
    if (width) {
      if (height) {
        value = `${config.proxyUrl}${transform}/${width}x${height}${filename}`
      } else {
        value = `${config.proxyUrl}${transform}/${width}x${filename}`
      }
    } else if (height) {
      value = `${config.proxyUrl}${transform}/x${height}${filename}`
    } else {
      if (!idxHttp) {
        value = `${config.proxyUrl}${filename}`
      } else {
        value = value.replace('http://', 'https://')
        value = value.replace(config.bucketUrl, config.proxyUrl)
      }
    }
  }
  return value
}

function defaultValue (val, valdef) {
  if (val === undefined) return valdef
  return val
}

function ensureNumber (val) {
  if (typeof val === 'string') {
    return parseFloat(val)
  }
  return val
}

export default asset
export { hashCode, asset, defaultValue, ensureNumber }
